import "./contact.css"

import Email from "../../img/email.png"
import Address from "../../img/address.png"
import { useRef, useState } from "react";
import emailjs from 'emailjs-com';


const Contact = () => {
    const formRef = useRef();
    const [done,setDone] = useState(false)
    const handleSubmit = (e) =>{
        e.preventDefault();
        emailjs.sendForm('service_8nohz0i', 'template_1oku9fj', formRef.current, 'DJBcWR4C5YsGJHzz6')
    .then((result) => {
        console.log(result.text);
        setDone(true)
    }, (error) => {
        console.log(error.text);
      });
    }
    return (
        <div className="c">
           <div className="c-bg"></div>
               <div className="c-wrapper">
                <div className="c-left">
                    <h1 className="c-title">
                        Lets discuss your project
                    </h1>
                    <div className="c-info">
                        <div className="c-info-item">
                            <img src={Email} alt="" className="c-icon" />
                            sushruttandon@gmail.com
                        </div>
                    </div>
                    <div className="c-info">
                        <div className="c-info-item">
                            <img src={Address} alt="" className="c-icon" />
                            Kathmandu, Nepal
                        </div>
                    </div>
                </div>
                <div className="c-right">
                    <p className="c-desc">
                        <b>What's on your mind?</b> Let's get in touch. I am always available for freelancing if the right project comes along.
                    </p>
                    <form ref={formRef} onSubmit={handleSubmit}>
                        <input type="text" placeholder="Name" name="user_name" className="apple" required/>
                        <input type="text" placeholder="Subject" name="user_subject" className="apple" required/>
                        <input type="text" placeholder="Email" name="user_email" className="apple" required/>
                        <textarea placeholder="Message" rows="5" name="message" className="apple" required></textarea>
                        <button>Submit</button>
                        {done && " Thank you." }
                    </form>
                </div>
               </div>
        </div>
    );
};

export default Contact