import About from "./components/about/about";
import Contact from "./components/contact/contact";
import Footer from "./components/footer/footer";
import Intro from "./components/intro/Intro";
import Nav from "./components/navabar/nav";
import ProductList from "./components/productList/ProductList";
import {
  BrowserRouter,
  Routes,
  Route,
  Link
} from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";

const App = () => {
  return(
    <BrowserRouter>
    <ScrollToTop/>
 
          <Nav />
          
     <div>

        <Routes>
          <Route path="/" element={<Intro />} />
        </Routes>
        <Routes>
          <Route path="/about" element={<About />} />
        </Routes>
        <Routes>
          <Route path="/product" element={<ProductList />} />
        </Routes>
        <Routes>
          <Route path="/contact" element={<Contact />} />
        </Routes>

      <Footer />
      

  
     </div>
      </BrowserRouter>
     );
};

export default App;