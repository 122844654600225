
export const products = [
  {
    id: 1,
    img: "img/das_p1.png",
    link: "https://doubleasoft.com/",
  },
  {
    id: 2,
    img: "img/1zmP3.png",
    link: "https://1zenman.com/",
  },
  {
    id: 3,
    img: "img/ak_p2.png",
    link: "https://sushruttandon.com.np/",
  },
  {
    id: 4,
    img: "img/sufp1.png",
    link: "https://sushruttandon.com.np/",
  },
  {
    id: 5,
    img: "img/uc_p4.png",
    link: "https://sushruttandon.com.np/",
  },
  {
    id: 6,
    img: "img/Ts_v1.jpg",
    link: "https://sushruttandon.com.np/",
  },
];