import React from 'react';
import "./footer.css"
import logo from "../../img/MyLogo_T.png"
import {NavLink} from 'react-router-dom';
const Footer = () => {
    return(
        <div className="footer">
            <a href="#" className="footer-logo">Sushrut Tandon</a>

            <ul className="permalinks">

             <NavLink to="/"> 
                <li className="footListItem">Home</li>
                </NavLink>
                          <NavLink to="/about"> 
                 <li className="footListItem">About</li>
                </NavLink>
                          <NavLink to="/product"> 
               <li className="footListItem">Project</li>
                </NavLink>
                          <NavLink to="/"> 
                <li className="footListItem">Blog</li>
                </NavLink>
                          <NavLink to="/contact"> 
                 <li className="footListItem">Contact</li>
                </NavLink>

               
            </ul>

            <div className="footer-socials">
                <a href="#"><i class="fa-brands fa-facebook"></i></a>
                <a href="#"><i class="fa-brands fa-twitter"></i></a>
                <a href="#"><i class="fa-brands fa-linkedin-in"></i></a>
            </div>
            <div className="footer-copyright">
                <small>&copy; Sushrut Tandon. All rights reserved</small>
            </div>
        </div>
    )
}

export default Footer
