import "./about.css"
import Me from "../../img/image6BD.png"

const About = () => {
    return(
        <div className="a">
            <div className="a-left">
                <div className="a-card bg"></div>
                <div className="a-card">
                     <img src={Me} alt="" className="a-img" />
                </div>
            </div>  
            <div className="a-right">
                <h1 className="a-title">About Me</h1>
                <p className="a-sub">
                    I like to keep it 100% and code with zen.
                </p>
                <p className="a-desc">
                    Highly responsible, creative, with initiative and punctuality. I responsibly assume the challenges and goals that the work assigns me, adaptability to change, staff management, ability to work in teams, as well as to solve problems efficiently and achieve productivity goals set by the company and my work group.
                </p>
                
            </div>
        </div>
    )
}

export default About