import React from 'react';
import "./nav.css"
import logo from "../../img/MyLogoR.png"
import {NavLink} from 'react-router-dom';


const Nav = () => {
    return (
        <div className="nav-top">
          <div className="topLeft">
              <a href="https://www.facebook.com/sushrut.tandon.712">
                <i className="topIcon fa-brands fa-facebook-f"></i>
              </a>
              <a href="https://twitter.com/TandonSushrut">
                <i className="topIcon fa-brands fa-twitter"></i>
              </a>
              <a href="https://www.linkedin.com/in/sushrut-tandon-9134b912a/">
                <i className="topIcon fa-brands fa-linkedin-in"></i>
              </a>
              <a href="#">
                 <i className="topIcon fa-brands fa-instagram"></i>
              </a>
          </div>
          <div className="topCenter">
             <NavLink to="/"> 
                <img className="topImg" src={logo} alt="" />
                </NavLink>
            
          </div>
          <div className="topRight">
              <ul className="topList">
                <NavLink to="/"> 
                <li className="topListItem">Home</li>
                </NavLink>
                <NavLink to="/about"> 
               <li className="topListItem">About</li>
                </NavLink>
                <NavLink to="/product"> 
              <li className="topListItem">Project</li>
                </NavLink>
                <NavLink to="/"> 
               <li className="topListItem">Blog</li>
                </NavLink>
                <NavLink to="/contact"> 
               <li className="topListItem">Contact</li>
                </NavLink>
 
              </ul>

          </div>
        </div>  
    )
}

export default Nav